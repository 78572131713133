.btn {
    position: relative;
    width: 272px;
    padding: 12px 16px;
    margin-top: 13px;
    background-color: #0095ff;
    border: none;
    color: #fff;
    font-weight: 400;
    cursor: pointer;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    display: inline-block;
    text-decoration: none;
    border-radius: 8px;
  }
  
  .btn:hover {
    background-color: #70b4e6;
  }
  
  .btn[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }
  
  .btn.active {
    background-color: #23c9ff;
    border: 1px solid #5eb2f2;
    box-shadow: 0px 0 2px 0px #c0c0c0;
  }
  
  .btn.invert {
    background-color: #fff;
    color: #000;
    border: 1px solid rgba(0, 0, 0, 0.2);
  }